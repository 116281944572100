.Main-about-data , .event-recap-story {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100vw;
    height: fit-content;
  }
   .event-recap-story {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
  }

  .event-recap-heading>h1::after {
    content: '';
    display: block;
    position: absolute;
    height: 6px;
    width: 85px;
    border: 1px solid transparent;
    border-radius: 8px;
    background: linear-gradient(to right, #113963, #B1261F);
  }
  
  .par1{
    display: flex;
    width: 93vw;
    flex-direction: column;
    padding-top: 1rem ;
  }
  
  .par1>p {
    font-weight: 400;
    font-family: 'Gilroy';
    font-size: 20px;
    line-height: 29px;
    padding-right: 2%;
  margin:0
  }
  .par{
    order: 2;
  }

  .par1>p {
  padding-top: 1rem;
  }
  .event-recap-story {
    width: 93vw;
  }


  @media screen and (max-width:560px)  {
    .par1>p {
        font-size: 15px;
        line-height: 23px;
        padding-left: 2%;
        padding-top: 0%;
      }
  }
  @media screen and (max-width:420px)  {
    .event-recap-image > div {
    
      width: 310px !important;
      height: 220px !important;
    }
    .event-recap-image > div > div{
      
      width: 310px !important;
      height: 220px !important;
    }
  
    .rsis-container{
      width: 310px !important;
      height: 220px !important;
    }
  
    .rsis-image{
      width: 310px !important;
      height: 220px !important;
    }
  
  }