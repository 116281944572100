.Main-about-data , .about-us-story {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100vw;
    height: fit-content;
  }
   .about-us-story {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
  }
  
  .para{
    display: flex;
    justify-content: space-around;
    width: 93vw;
  }
  
  .para>p {
    font-weight: 400;
    font-family: 'Gilroy';
    font-size: 20px;
    line-height: 29px;
    margin: 0;
    padding: 0;
    padding-right: 2%;
  
  }

  .about-us-story {
    width: 93vw;
  }

  .rsis-container{
    width: 400px !important;
    height: 300px !important;
  }

  .rsis-image{
    width: 400px !important;
    height: 300px !important;
  }
  
  
  .about-us-image {
    
    padding-bottom: 15px;
  }

  .about-us-image > div {
    
    width: 400px !important;
    height: 300px !important;
  }
  .about-us-image > div > div{
    
    width: 400px !important;
    height: 300px !important;
    
    
  }

  .about-us-image > div > div >div:nth-child(2){
    margin-left: -90.5px !important;
  }
  
  .about-us-image > div > div >div:nth-child(2) > button {
    
    width: 8px !important;
    height: 8px !important;
 
  }

 
  
  
  @media screen and (max-width:1000px)  {
    .para{
        flex-wrap: wrap;
    }
    .pa{
        order: 2;
      }

      .para>p {
      padding-top: 2%;
      }

      .about-us-image {
        order: 1;
        width: fit-content;
        height: fit-content;
      }
  }

  @media screen and (max-width:560px)  {
    .para>p {
        font-size: 15px;
        line-height: 23px;
        padding-left: 2%;
        padding-top: 0%;
      }
  }
  @media screen and (max-width:420px)  {
    .about-us-image > div {
    
      width: 310px !important;
      height: 220px !important;
    }
    .about-us-image > div > div{
      
      width: 310px !important;
      height: 220px !important;
    }
  
    .rsis-container{
      width: 310px !important;
      height: 220px !important;
    }
  
    .rsis-image{
      width: 310px !important;
      height: 220px !important;
    }
  
  }