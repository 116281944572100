.schedule{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100vw;
    padding-left: 3.2%;
}

.paragraph{
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 52px;
    letter-spacing: 1px;
    color: #212427; 
}

@media screen and (max-width:440px)  {
    .paragraph{
        font-size: 18px;
    }
  }