@font-face {
  font-family: Gilroy;
  src: url('./Home/gilory.ttf') format('truetype');
  font-weight: bolder;
}

body {
  margin: 0;
  font-family: Gilroy, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: Gilroy, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.navbar1 {
  background: #2E435C;
  margin-bottom: -2.5%;
  height: 10%;
  position: fixed;
  padding: 5px 0 5px 0;
  width: 100vw;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}

.navbar1>div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: center;
  padding-right: 30px;
}

.hamburger1 {
  display: none;
  background: transparent;
  border: none;
  color: white;
  float: right;
  cursor: pointer;
}

.register {
  height: 40px;
  width: 100px;
  background-color: white;
  color: black;
  border: 1px solid transparent;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  cursor: pointer;
}

 a {
  color: rgb(231, 230, 230) !important;
  text-decoration: none;
  font-size: 16px;
  font-family: 'Gilroy';
  letter-spacing: 0.01em;
  padding: 0 1rem;
}


.text > a {
  color: rgb(231, 230, 230) !important;
  text-decoration: none;
  font-size: 16px;
  font-family: 'Gilroy';
}

a:hover {
  cursor: pointer;
  text-decoration: none;
  text-shadow: 0 0 1px white, 0 0 1px white, 0 0 1px white;
}

.text > a:hover{
  cursor: pointer;
  text-decoration: none;
  text-shadow: 0 0 1px white, 0 0 1px white, 0 0 1px white;
} 

.active {
  text-decoration: none;
  text-shadow: 0 0 1px white, 0 0 1px white, 0 0 1px white; 
}

.mobile-links {
  visibility: hidden;
}

/* ------------------------------------------------------------------------------------- */
.Footer {
  width: 100vw;
  height: fit-content;
  background-color: #25384C;
  padding: 15px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-us {
  height: fit-content;
  padding: 15px 0px;
  width: 100vw;
  padding-left: 2.5%;
  font-family: 'Gilroy';
}

.contact-us-details {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  font-family: 'Gilroy';
}

.college-name,
.college-address,
.mobile-number,
.site-link {
  display: flex;
  padding: 7px 0;
  align-items: center;
  width: fit-content;
  justify-content: space-between;
}

.text {
  padding-left: 10px;
}

.text > a > p{
  margin: 0;
}

.contact-heading>h1 {
  color: white;
  font-family: 'Gilroy';
}

.text>p,
.icon {
  color: white;
  font-family: 'Gilroy';
}

.QR-50 {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0px;
  flex-direction: column;
}

.scan-text {
  color: white;
  font-family: 'Gilroy';
}

.QR-Box {
  height: 280px;
  width: 280px;
  /* background-color: #D9D9D9; */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* -------------------------------------------------------------------------------------- */

.Bg-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.767), rgba(0, 0, 0, 0.514)), url('./Home/tenis.svg');
  /* background-image: url('./Home/tenis.svg'); */
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.college-name>.text {
  cursor: pointer;
}

.full-info {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.Main-Heading {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: fit-content;
  padding: 34px 0px 0px 0px;
}

.details {
  display: flex;
  flex-direction: column;
  width: 65vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  margin-left: 5%;
}

.prize {
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 90vh;
}

.image {
  height: 270px;
  width: 270px;
}



.dates {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

.Main-Heading>h1,
.Main-Heading>p {
  margin: 0;
  padding: 0;
  color: white;
}

.Main-Heading>p {
  padding: 11px 0px;
}

.qr {
  height: 240px;
  width: 240px;
}


.text>h1,
.Main-Heading>h1 {
  color: white;
  font-size: 45px;
}

.Main-Heading>h1 {
  color: white;
  font-size: 40px;
}



.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}


.about-us-heading>h1,
.category-heading>h1,
.heading>h1,
.heading-student>h1,
.eligibility-heading>h1 {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 60px;
  padding-bottom: 2%;
}
.about-us-heading,
.category-heading,
.heading,
.heading-student,
.eligibility-heading,
.heading1 {
  
  padding: 1% 0%;
}

.about-us-heading>h1::after,
.category-heading>h1::after,
.heading>h1::after,
.heading-student>h1::after,
.eligibility-heading>h1::after {
  content: '';
  display: block;
  position: absolute;
  height: 6px;
  width: 85px;
  border: 1px solid transparent;
  border-radius: 8px;
  background: linear-gradient(to right, #113963, #B1261F);
}

.gap {
  display: none;
  padding: 8px 0px;
}
.heading>h1::after {
  content: '';
  display: block;
  position: absolute;
  height: 6px;
  width: 240px;
  border: 1px solid transparent;
  border-radius: 8px;
  background: linear-gradient(to right, #113963, #B1261F);
}

.organiser {
  width: 90vw;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 2.7%;
}

.arrange {
  display: flex;
  align-items: center;
}

.student-list {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 39px;
  /* identical to box height */

  letter-spacing: 0.0075em;

  color: #212427;

}

.committee-list,.student-list {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 34px;

  letter-spacing: 0.0075em;
  padding-bottom: 8px;

  color: #212427;
}

.arrange{
  /* style={{ fontFamily: "Gilroy", fontSize: "22px", fontWeight: "500" }} */
  font-family: 'Gilroy';
  font-size: 22px;
  font-weight: 500;
}

.criteria {
  font-size: 24px;
  line-height: 43px;
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.0075em;
  color: #212427;
}


.heading1>h1 , .heading-student>h1{
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 60px;
  padding-bottom: 2%;
}

.heading1{
  
  padding: 1% 0%;
}


.heading1>h1::after , .heading-student>h1::after{
  content: '';
  display: block;
  position: absolute;
  height: 6px;
  width: 85px;
  border: 1px solid transparent;
  border-radius: 8px;
  background: linear-gradient(to right, #113963, #B1261F);
}

.student-list {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 39px;
  /* identical to box height */

  letter-spacing: 0.0075em;

  color: #212427;

}

.committee-list1 {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 52px;

  letter-spacing: 0.0075em;

  color: #212427;
}


@media screen and (max-width:1250px) {
  .heading>h1{
    font-size: 32px;
  }
  .heading>h1::after {
    content: '';
    display: block;
    position: absolute;
    height: 6px;
    width: 200px;
    border: 1px solid transparent;
    border-radius: 8px;
    background: linear-gradient(to right, #113963, #B1261F);
  }
  .arrange{
    font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  }
}


@media screen and (max-width:1025px) {

  .organiser {
    margin-left: 0px;
  }
  .links {
    visibility: hidden;
  }

  .gap{
    display: block;
    padding: 10px 0px;
  }
  .hamburger1 {
    display: block;
    float: right;
  }

  .mobile-links {
    display: flex;
    visibility: visible;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    background-color: #192b42;
    z-index: 2;
    box-shadow: 0px 7px 12px -2px rgba(0, 0, 0, 0.69);
    list-style: none;
    line-height: 30px;
  }

  .heading>h1{
    font-size: 27px;
  }
  .heading>h1::after {
    content: '';
    display: block;
    position: absolute;
    height: 6px;
    width: 180px;
    border: 1px solid transparent;
    border-radius: 8px;
    background: linear-gradient(to right, #113963, #B1261F);
  }

  a {
    color: rgb(231, 230, 230) !important;
    text-decoration: none;
    font-size: 16px;
    font-family: 'Gilroy';
    letter-spacing: 0.01em;
    padding: 8px 0;
  }

  a:hover {
    cursor: pointer;
    text-shadow: 0 0 1px white, 0 0 1px white, 0 0 1px white;
  }

  .active {
    text-shadow: 0 0 1px white, 0 0 1px white, 0 0 1px white;
  }


  .navbar1>div {
    padding-right: 0px;
  }

}

@media screen and (max-width:820px) {

  .category-heading>h1 {
    margin-left: 0px;
  }

  .about-us-heading {
    margin-left: 0px;
  }

  .Footer {
    flex-wrap: wrap;
    flex-direction: coloum;
  }

  .text>h1,
  .Main-Heading>h1 {
    color: white;
    font-size: 31px;
  }

  .Main-Heading>h1 {
    color: white;
    font-size: 23px;
  }

  .image>img {
    height: 429px;
    width: 189px;
  }

  .details {
    width: 100vw;
  }

  .prize {
    width: 35vw;
  }

  .about-us-story {
    width: 71vw;
    padding: 6px 0px;
    order: 2;
  }

  .about-us-image {
    order: 1;
  }

  .about {
    align-items: center;
  }

  .content {
    flex-direction: column;
  }

  .Bg-image {
    background-image: linear-gradient(rgba(0, 0, 0, 0.767), rgba(0, 0, 0, 0.514)), url('./Home/mobilebg.svg');
    /* background-image: url('./Home/tenis.svg'); */
    background-repeat: no-repeat;
    background-size: content;
    height: 50vh;
    position: relative;
    overflow: hidden;
  }

  .Main-Heading {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    top: 26%;
  }

  .prize {
    width: 50vw;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: fit-content;
    height: 48vh;
  }

}

@media screen and (max-width:670px) {
  .heading>h1{
    font-size: 24px;
    font-weight: bolder;
    width: fit-content;
  }

  .heading{
    padding-bottom: 10px;
  }
  .heading>h1::after {
    content: '';
    display: block;
    position: absolute;
    height: 6px;
    width: 120px;
    border: 1px solid transparent;
    border-radius: 8px;
    background: linear-gradient(to right, #113963, #B1261F);
  }
}

@media screen and (max-width:560px) {

  .heading>h1{
    font-size: 24px;
  }
  .heading>h1::after {
    content: '';
    display: block;
    position: absolute;
    height: 6px;
    width: 120px;
    border: 1px solid transparent;
    border-radius: 8px;
    background: linear-gradient(to right, #113963, #B1261F);
  }

  .arrange{
    font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  }

  .text>h1,
  .Main-Heading>h1 {
    color: white;
    font-size: 18px;
  }

  .Main-Heading>h1,
  .Main-Heading>p {
    color: white;
    font-size: 18px;
  }

  .Main-Heading>p {
    color: white;
    font-size: 14px;
  }

  .image>img {
    height: 429px;
    width: 189px;
  }

  .details {
    width: 100vw;
  }

  .prize {
    width: 35vw;
  }

  .about-us-image>img,
  .category-image>img {
    height: 310px;
    width: 310px;
  }

  .listed-data>ul>li {
    font-size: 16px;
  }

  .category-story {
    position: relative;
    top: -32px;
  }

  .Bg-image {
    background-image: linear-gradient(rgba(0, 0, 0, 0.767), rgba(0, 0, 0, 0.514)), url('./Home/mobilebg.svg');
    /* background-image: url('./Home/tenis.svg'); */
    background-repeat: no-repeat;
    background-size: content;
    height: 90vh;
    position: relative;
    overflow: hidden;
  }

  

}

@media screen and (max-width:440px) {

  .criteria{
    font-size: 17px;
    line-height: 38px;
  }

  .arrange{
    font-size: 16px;
  }

  .logo-image {
    width: 180px;
    height: 100%;
  }

  .heading{
    padding-bottom: 24px;
  }


  .about-us-story {
    width: 80vw;
  }

  .heading1>h1,
  .heading-student>h1 {
    font-size: 36px;
    margin-left: 2px;
  }

  .heading1>h1,
  .heading-student>h1 {
    font-size: 26px;
    margin-left: 0px;
  }

  .heading1>h1::after,
  .heading-student>h1::after{
    content: '';
    height: 5px;
    width: 60px;
  }

  .committee-list1 {
    font-weight: 400;
    font-size: 20px;
    line-height: 35px;
  }

  .student-list {
    font-weight: 400;
    font-size: 20px;
    line-height: 35px;
  }

  .about-us-heading>h1,
  .category-heading>h1,
  .heading>h1,
  .heading-student>h1 {
    font-size: 36px;
    margin-left: 2px;
  }

  .about-us-heading>h1,
  .eligibility-heading>h1 {
    font-size: 27px;
  }
  .eligibility-heading>h1 {
    font-size: 30px;
  }

  .heading>h1,
  .heading-student>h1 {
    font-size: 26px;
    margin-left: 0px;
  }

  .organizing-committee,
  .student {
    width: 100vw;
  }

  .about {
    align-items: normal;
  }

  .about-us-heading>h1::after,
  .category-heading>h1::after,
  .heading>h1::after,
  .heading-student>h1::after,
  .eligibility-heading>h1::after {
    content: '';
    height: 5px;
    width: 60px;
  }

  .committee-list {
    font-weight: 400;
    font-size: 20px;
    line-height: 35px;
  }

  .student-list {
    font-weight: 400;
    font-size: 20px;
    line-height: 35px;
  }

  .Bg-image {
    background-image: linear-gradient(rgba(0, 0, 0, 0.767), rgba(0, 0, 0, 0.514)), url('./Home/mobilebg.svg');
    /* background-image: url('./Home/tenis.svg'); */
    background-repeat: no-repeat;
    background-size: cover;
    height: 37vh;
    position: relative;
    overflow: hidden;
  }

  .Main-Heading {
    position: absolute;
    top: 10%;
    width: 100vw;
    left: 4%;
    padding: 23px 5px;
  }

  .image>img {
    height: 93px;
    width: 95px;
  }

  .prize {
    height: 33vh;
  }

  .image {
    height: 64px;
    width: 116px;
  }

  .text {
    font-size: 14px;
  }

  .paragraph{
    font-size: 20px;
  }

  .contact-heading>h1 {
    font-size: 26px;
  }

  .heading>h1::after {
    content: '';
    display: block;
    position: absolute;
    height: 6px;
    width: 180px;
    border: 1px solid transparent;
    border-radius: 8px;
    background: linear-gradient(to right, #113963, #B1261F);
  }

}

@media screen and (max-width:400px) {

  .listed-data>ul>li {
    font-size: 13px;
    font-weight: 700;
  }

  .committee-list {
    font-weight: 400;
    font-size: 16px;
    line-height: 35px;
  }
  .committee-list1 {
    font-weight: 400;
    font-size: 16px;
    line-height: 35px;
  }

  .student-list {
    font-weight: 400;
    font-size: 16px;
    line-height: 35px;
  }

 

  .QR-Box {
    height: 240px;
    width: 240px;
  }

  .qr {
    height: 230px;
    width: 230px;
  }

  .category-story {
    position: relative;
    top: -32px;
  }

}

@media screen and (max-width:340px) {

  .image>img {
    height: 425px;
    width: 145px;
  }

  .listed-data>ul>li {
    font-size: 11px;
    font-weight: 700;
  }

}